<template>
  <div class="dashboard">
    <DefaultLayout>
      <slot></slot>
      <FilterBox :roles="roles" />
      <b-table
        class="custom-table"
        :items="items"
        :busy="loading"
        :fields="fields"
        responsive
        :sticky-header="true"
        show-empty
        :tbody-transition-props="transProps"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #empty>
          <div class="text-center p-4">
            {{ $t("GLOBAL.NO_DATA") }}
          </div>
        </template>
        <template #cell(id)="data">
          <div>
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(name)="data">
          <div>
            <div class="table-profile">
              <p>{{ data.item.name_en }}</p>
            </div>
          </div>
        </template>
        <template #cell(km_price)="data">
          <div>
            {{ data.item.km_price }}
          </div>
        </template>
        <template #cell(start_price)="data">
          <div>
            {{ data.item.start_price }}
          </div>
        </template>
        <template #cell(fixed_price)="data">
          <div>
            {{ data.item.fixed_price }}
          </div>
        </template>
        <template #cell(status)="data">
          <div>
            <span class="status-circle" :class="data.item.status == 1 ? 'active' : 'inactive'">{{
              data.item.status == 1 ? $t("GLOBAL.ACTIVE") : $t("GLOBAL.INACTIVE")
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <div class="table-actions">
              <img src="@/assets/images/icons/table-delete.svg" @click="handleDelete(data.item)" />
              <img src="@/assets/images/icons/table-edit.svg" @click="handleEdit(data.item)" />
            </div>
          </div>
        </template>
      </b-table>
      <CustomPagination
        :current_page="current_page"
        :requestStatus="requestStatus"
        :links="links"
        @paginatePrev="paginatePrev"
        @paginateNext="paginateNext"
        @getPagination="getPagination"
      />
      <!-- <Pagination
        :current-page="current_page"
        :total-items="total_items"
        :per-page="per_page"
        class="pagination"
      /> -->
      <EditTransport :transport="transport" @editTransport="handleSaveChanges" />
      <AddTransport @addTransport="handleAddTransport" />
      <ModalDetails :user="user" />
    </DefaultLayout>
  </div>
</template>

<script>
import FilterBox from "@/components/Shared/FilterBox/index.vue"
import EditTransport from "@/components/Shared/EditTransport/index.vue"
import AddTransport from "@/components/Shared/AddTransport/index.vue"
import CustomPagination from "@/components/Shared/CustomPagination/index.vue"
import {
  createTransport,
  getTransportsData,
  deleteTransport,
  getTransportByIdRequest,
  updateTransport
} from "@/api/transports.js"
import { mapActions } from "vuex"
import ModalDetails from "../../../Shared/EditUserDetails/index.vue"

export default {
  props: {
    type: {
      type: Boolean
    }
  },
  components: {
    FilterBox,
    EditTransport,
    AddTransport,
    ModalDetails,
    CustomPagination
  },
  data() {
    return {
      toggleVal: false,
      logs: [],
      user: {},
      transport: {},
      transProps: {
        name: "flip-list"
      },
      loading: false,
      default_avatar: require("@/assets/images/user.png"),
      requestStatus: "all",
      links: "",
      current_page: 1,
      total_items: 0,
      per_page: 0,
      roles: [
        {
          id: 1,
          name: "role 1"
        },
        {
          id: 2,
          name: "role 2"
        },
        {
          id: 3,
          name: "role 3"
        }
      ],
      fields: [
        { key: "id", label: "#", sortable: false },
        {
          key: "name",
          label: this.$t("GLOBAL.NAME"),
          sortable: false
        },
        {
          key: "km_price",
          label: this.$t("GLOBAL.KM PRICE"),
          sortable: false
        },
        {
          key: "start_price",
          label: this.$t("GLOBAL.START PRICE"),
          sortable: false
        },
        {
          key: "fixed_price",
          label: this.$t("GLOBAL.FIXED PRICE"),
          sortable: false
        },
        {
          key: "status",
          label: this.$t("GLOBAL.STATUS"),
          sortable: false
        },
        {
          key: "created_at",
          label: this.$t("GLOBAL.created_at"),
          sortable: false
        },
        {
          key: "actions",
          label: this.$t(""),
          sortable: false
        }
      ],
      items: []
    }
  },
  mounted() {
    this.getTransactions()
  },
  methods: {
    ...mapActions(["ShowToast"]),
    handleEdit(obj) {
      this.ApiService(getTransportByIdRequest(obj.id))
        .then((res) => {
          this.transport = res.data.data
          this.$bvModal.show("edit-transport")
        })
        .catch((err) => {
          this.ShowToast({
            title: this.$t("Error"),
            type: "danger",
            message: this.$t(err)
          })
        })
    },
    handleDelete(obj) {
      this.ApiService(deleteTransport(obj.id)).then((res) => {
        if (res.data.status == "success") {
          this.items.splice(this.items.indexOf(obj), 1)
          this.ShowToast({
            title: this.$t("Delete User"),
            type: "danger",
            message: this.$t("Deleted Success")
          })
          return res
        }
      })
    },
    handleAddTransport(data) {
      this.ApiService(createTransport(data)).then((res) => {
        if (res.data.status == "success") {
          this.$bvModal.hide("add-user")
          location.reload()
        } else {
          // this.ShowToast({
          //     title: this.$t("Delete User"),
          //     type: "danger",
          //     message: elem
          //   })
          for (const key of Object.keys(res.data.content)) {
            console.log(`${key}: ${person[key]}`)
          }
        }
      })
    },
    handleSaveChanges(data) {
      console.log(data)
      this.loading = true
      this.ApiService(updateTransport(data)).then((res) => {
        if (res.data.status == "success") {
          this.items.splice(this.items.indexOf(obj), 1)
          this.ShowToast({
            title: this.$t("Transport Updated"),
            type: "success",
            message: this.$t("Transport Updated")
          })
          return res
        }
      })
    },
    getTransactions() {
      this.loading = true
      this.ApiService(getTransportsData(1))
        .then((res) => {
          if (res.data.status != "success") {
            console.log(res)
            this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
          }
          this.items = res.data.data.data
          this.current_page = res.data.data.current_page
          this.total_items = res.data.data.total
          this.per_page = res.data.data.per_page
          this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
          this.activeLinks = this.links.slice(0, 4)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  getPagination(v) {
    alert(v.page)
    this.loading = true
    this.ApiService(getTransportsData(v.page)).then((res) => {
      if (res.data.status == "success") {
        this.items = res.data.data.data
        this.current_page = res.data.data.current_page
        this.total_items = res.data.data.total
        this.per_page = res.data.data.per_page
        this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
        this.activeLinks = this.links.slice(0, 4)
        this.loading = false
      }
    })
  },
  paginateNext(v) {
    if (v.c_page != this.links.length) {
      this.loading = true
      this.ApiService(getUsersRequest(v.c_page + 1)).then((res) => {
        if (res.data.status == "success") {
          this.items = res.data.data.data
          this.current_page = res.data.data.current_page
          this.total_items = res.data.data.total
          this.per_page = res.data.data.per_page
          this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
          this.loading = false
        }
      })
    } else {
      return false
    }
  },
  paginatePrev(v) {
    if (v.c_page != 1) {
      this.loading = true
      this.ApiService(getUsersRequest(v.c_page - 1)).then((res) => {
        if (res.data.status == "success") {
          this.items = res.data.data.data
          this.current_page = res.data.data.current_page
          this.total_items = res.data.data.total
          this.per_page = res.data.data.per_page
          this.links = res.data.data.links.slice(1, res.data.data.links.length - 1)
          this.loading = false
        }
      })
    } else {
      return false
    }
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
