<template>
  <div class="drvers">
    <TransportationList :type="`managers`">
      <PageTitle title="TRANSPORTATION.TITLE" subtitle="TRANSPORTATION.SUBTITLE">
        <Button @click="handleAddTransport">
          <span class="fs-16">{{ $t("GLOBAL.ADD_TRANSPORT") }}</span>
          <img class="mx-2" src="@/assets/images/icons/add-user.svg" />
        </Button>
      </PageTitle>
    </TransportationList>
  </div>
</template>
<script>
import TransportationList from "@/components/Modules/Transportation/TransportationList/index.vue"
import PageTitle from "@/components/Shared/PageTitle/index.vue"
export default {
  components: {
    TransportationList,
    PageTitle
  },
  methods:{
    handleAddTransport(){
      // alert('test')
      this.$bvModal.show("add-transport")
    }
  }
}
</script>